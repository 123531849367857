/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Cart,
  CartCheckout,
  CompanySearchResponseList,
  PaginatedCartList,
} from '../models/index';
import {
    CartFromJSON,
    CartToJSON,
    CartCheckoutFromJSON,
    CartCheckoutToJSON,
    CompanySearchResponseListFromJSON,
    CompanySearchResponseListToJSON,
    PaginatedCartListFromJSON,
    PaginatedCartListToJSON,
} from '../models/index';

export interface CartCheckoutRequest {
    cartCheckout: CartCheckout;
}

export interface CartListRequest {
    page?: number;
}

export interface CartRetrieveRequest {
    id: number;
    companyUuid?: string;
}

export interface CreatePaymentIntentRequest {
    cartCheckout: CartCheckout;
}

export interface SearchForCompanyRequest {
    cartSlug: string;
    corporationName?: string;
    userId?: string;
}

/**
 * CartApi - interface
 * 
 * @export
 * @interface CartApiInterface
 */
export interface CartApiInterface {
    /**
     * 
     * @param {CartCheckout} cartCheckout 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApiInterface
     */
    cartCheckoutRaw(requestParameters: CartCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     */
    cartCheckout(requestParameters: CartCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

    /**
     * 
     * @summary List all carts
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApiInterface
     */
    cartListRaw(requestParameters: CartListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedCartList>>;

    /**
     * List all carts
     */
    cartList(requestParameters: CartListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedCartList>;

    /**
     * 
     * @summary Retrieve a cart
     * @param {number} id A unique integer value identifying this cart.
     * @param {string} [companyUuid] Company UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApiInterface
     */
    cartRetrieveRaw(requestParameters: CartRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Cart>>;

    /**
     * Retrieve a cart
     */
    cartRetrieve(requestParameters: CartRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Cart>;

    /**
     * 
     * @param {CartCheckout} cartCheckout 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApiInterface
     */
    createPaymentIntentRaw(requestParameters: CreatePaymentIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     */
    createPaymentIntent(requestParameters: CreatePaymentIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

    /**
     * Handle GET requests to search for companies by name or user ID, providing a structured response.
     * @summary Search for a company by name or userId
     * @param {string} cartSlug Cart slug parsed from the url
     * @param {string} [corporationName] Name of the company to search for
     * @param {string} [userId] User ID parsed from the url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApiInterface
     */
    searchForCompanyRaw(requestParameters: SearchForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySearchResponseList>>;

    /**
     * Handle GET requests to search for companies by name or user ID, providing a structured response.
     * Search for a company by name or userId
     */
    searchForCompany(requestParameters: SearchForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySearchResponseList>;

}

/**
 * 
 */
export class CartApi extends runtime.BaseAPI implements CartApiInterface {

    /**
     */
    async cartCheckoutRaw(requestParameters: CartCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['cartCheckout'] == null) {
            throw new runtime.RequiredError(
                'cartCheckout',
                'Required parameter "cartCheckout" was null or undefined when calling cartCheckout().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/cart/api/checkout/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CartCheckoutToJSON(requestParameters['cartCheckout']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async cartCheckout(requestParameters: CartCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.cartCheckoutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all carts
     */
    async cartListRaw(requestParameters: CartListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedCartList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/cart/api/cart/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedCartListFromJSON(jsonValue));
    }

    /**
     * List all carts
     */
    async cartList(requestParameters: CartListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedCartList> {
        const response = await this.cartListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a cart
     */
    async cartRetrieveRaw(requestParameters: CartRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Cart>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling cartRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyUuid'] != null) {
            queryParameters['company_uuid'] = requestParameters['companyUuid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/cart/api/cart/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartFromJSON(jsonValue));
    }

    /**
     * Retrieve a cart
     */
    async cartRetrieve(requestParameters: CartRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Cart> {
        const response = await this.cartRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createPaymentIntentRaw(requestParameters: CreatePaymentIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['cartCheckout'] == null) {
            throw new runtime.RequiredError(
                'cartCheckout',
                'Required parameter "cartCheckout" was null or undefined when calling createPaymentIntent().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/cart/api/create_payment_intent/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CartCheckoutToJSON(requestParameters['cartCheckout']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async createPaymentIntent(requestParameters: CreatePaymentIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createPaymentIntentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handle GET requests to search for companies by name or user ID, providing a structured response.
     * Search for a company by name or userId
     */
    async searchForCompanyRaw(requestParameters: SearchForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySearchResponseList>> {
        if (requestParameters['cartSlug'] == null) {
            throw new runtime.RequiredError(
                'cartSlug',
                'Required parameter "cartSlug" was null or undefined when calling searchForCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['cartSlug'] != null) {
            queryParameters['cart_slug'] = requestParameters['cartSlug'];
        }

        if (requestParameters['corporationName'] != null) {
            queryParameters['corporation_name'] = requestParameters['corporationName'];
        }

        if (requestParameters['userId'] != null) {
            queryParameters['user_id'] = requestParameters['userId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/cart/api/search/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySearchResponseListFromJSON(jsonValue));
    }

    /**
     * Handle GET requests to search for companies by name or user ID, providing a structured response.
     * Search for a company by name or userId
     */
    async searchForCompany(requestParameters: SearchForCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySearchResponseList> {
        const response = await this.searchForCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
